import React, {useEffect, useState, useCallback} from 'react';
import { Box, Grid, Typography, Button, IconButton } from '@mui/material';
import { useForm, FormProvider, useWatch } from "react-hook-form";
import {
  EditIcon,
  AddIcon,
  ActiveParkingIcon,
  ParkingDataIcon,
  DeleteParkingIcon
} from './SVGs';
import {cloneDeep} from 'lodash';
import Page from '../../components/Page';
import PlaceAndPrices from "../../components/PlaceAndPrices";
import {Footer} from './styles';
import { Link } from "react-router-dom";
import useAuth from '../../hooks/useAuth';

import Sidebar from '../../components/Sidebar'
import Helmet from '../../components/Helmet'
import ParkingService from '../../services/parking.service';
import { fetchParkings } from '../../thunks';

const defaultValues = {
  // parkingOffer: '',
  // parkingType: '',
  // parkingCity: '',
  // parkingBuildNumber: '',
  // parkingStreet: '',
  // parkingPhone: '',
  // parkingPeriod: {
  //   hour:  {checked: true, price: null},
  //   day:   {checked: true, price: null},
  //   week:  {checked: true, price: null},
  //   month: {checked: true, price: null},
  // },
  // numberParkingSpaces: 10,
  // parkingPhoto: null,
  // acceptTheSecurityTerms: false,

  id: null,
  user: {
    id: null,
    firstName: null,
    lastName: null,
    avatar: null,
    registrationNumber: null
  },
  isFree: false,
  title: "",
  address: "",
  latitude: 0,
  longitude: 0,
  hourly: false,
  daily: false,
  weekly: false,
  monthly: false,
  costPerHour: 0,
  costPerDay: 0,
  costPerWeek: 0,
  costPerMonth: 0,
  booking: {
    status: 0,
    startTimestamp: null,
    endTimestamp: null,
    bookingStatus: 0,
    redirectUrl: null
  },
  rating: 0,
  countRating: 0,
  type: 1,
  countOfParking: 1,
  images: []
}

const Parking = () => {
  const [activeFormParking, setActiveFormParking] = useState(false);
  const [isEditParking, setIsEditParking] = useState(null);
  const [parkings, setParkings] = useState([]);
  const methods = useForm({defaultValues: cloneDeep(defaultValues)});
  const {reset,control} = methods;
  const watchInfo = useWatch({control, name: 'parkingPeriod'})
  const {accessToken} = useAuth();

  const editParking = useCallback((parking) => {
    setActiveFormParking(true);
    const newValues = Object.assign({
      parkingAddress: parking.address,
      parkingPeriod: {
        hour: {checked: !!parking.costPerHour, price: parking.costPerHour},
        day: {checked: !!parking.costPerDay, price: parking.costPerDay},
        week: {checked: !!parking.costPerWeek, price: parking.costPerWeek},
        month: {checked: !!parking.costPerMonth, price: parking.costPerMonth},
      },
      parkingPhotoToDelete: [],
      numberParkingSpaces: `${parking.countOfParking}`
    }, parking);
    setIsEditParking(newValues);
    reset(newValues)
  }, [reset])

  const fetchAndSetParkingsData = useCallback(async () => {
    const parkingsData = await fetchParkings(accessToken);
    setParkings(parkingsData);
    if(parkingsData.length === 1) {
      editParking(parkingsData[0])
    }
  }, [accessToken, editParking])

  useEffect(() => {
    fetchAndSetParkingsData();
  }, [accessToken, editParking, fetchAndSetParkingsData])

  const onSubmit = async (values) => {
    console.log("🚀 ~ file: Parking.js ~ line 45 ~ onSubmit ~ values", values)
    const data = {
      costPerHour: values.parkingPeriod.hour.price,
      costPerDay: values.parkingPeriod.day.price,
      costPerWeek: values.parkingPeriod.week.price,
      costPerMonth: values.parkingPeriod.month.price,
      daily: values.parkingPeriod.day.checked,
      weekly: values.parkingPeriod.week.checked,
      hourly: values.parkingPeriod.hour.checked,
      monthly: values.parkingPeriod.month.checked,
      parkingPhoto: values.parkingPhoto,
      countOfParking: +values.numberParkingSpaces
    }

    const parkingService = new ParkingService();
    await parkingService.updateParking(accessToken, isEditParking.id,  {...values, ...data});
    if(values?.parkingPhotoToDelete) {
      await Promise.all(values?.parkingPhotoToDelete.map(async (photo) => {
        await parkingService.deleteParkingImage(accessToken, photo.url);
      }))
    }

    setActiveFormParking(false);
    setIsEditParking(null);
    
    fetchAndSetParkingsData();
  }

  const onCancel = () => {
    setActiveFormParking(false);
    setIsEditParking(null);
    reset({ parkingStreet: defaultValues.address })
  }

  const deleteParking = async (parkingId = isEditParking.id) => {
    const parkingService = new ParkingService();
    const response = await parkingService.deleteParking(accessToken, parkingId);
    setParkings(parkings.filter(parking => parking.id !== parkingId));
    if(response) {
      setActiveFormParking(false);
      setIsEditParking(null);
    }

  }

  const styleButton = {
    display: 'inline-flex',
    backgroundColor: '#5D31B8',
    boxShadow: 'none',
    borderRadius: '0.83vw',
    padding: '1.03vw 1.55vw',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '0.83vw',
    '&:hover': {
      backgroundColor: '#5D31B8',
    },
    marginLeft: '1vw'
  }
  const styleMain = {minHeight: "100vh", background: '#F0EFF4'}


  return (<Page title="Паркинг">
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={1.7}>
        <Sidebar />
      </Grid>
      
      <Grid item xs={1}></Grid>

      <Grid item xs={8}>
        <Helmet />

        <Box>
          <Typography sx={{
            fontSize: '1.55vw',
            color: '#5D31B8',
          }}>Паркинг</Typography>
        </Box>

        <Box sx={{ marginTop: '3vh'}}>
          <Box sx={{display: 'flex'}}>
            <Box><ActiveParkingIcon /></Box>
            <Typography sx={{
              lineHeight: 1.4,
              fontSize: '1.03vw',
              marginBottom: '3vh',
              color: '#5D31B8',
              marginLeft: '1vw'
            }}>Активные парковки</Typography>
          </Box>
         
          <Box sx={{
            display: 'flex', 
            marginTop: '3vh', 
            flexWrap: 'wrap', 
            justifyContent: 'center',
            "& .MuiGrid-container > .MuiGrid-item": {
              paddingTop: 0
            }
          }}>
            <Grid container spacing={3}>
              {parkings?.map((item, index) => (
                <Grid key={index} item xs={3}>
                  <Box 
                    key={`parking_${item.id}`}
                    sx={{
                      border: item.id === isEditParking?.id ? '1px solid #5D31B8' : '1px solid white',
                      color: item.id === isEditParking?.id ? '#5D31B8' : 'inherit',
                      backgroundColor: '#fff',
                      borderRadius: '0.83vw',
                      padding: '0.77vw 1.03vw',
                      paddingRight: '0.5vw',
                      marginBottom: '0.5vw',
                      display: "flex",
                      minHeight: '6vh',
                      justifyContent: "space-between",
                      alignItems: "center",
                      '&:not(:last-child)': {
                        marginRight: '2vw'
                      },
                    }}
                  >
                    <Box>
                      <Typography sx={{fontSize: '0.77vw', width: '100%',  lineHeight: 'normal',}}>
                        {item.address}
                      </Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                      <IconButton onClick={()=> editParking(item)}><EditIcon /></IconButton>
                      <IconButton onClick={()=> deleteParking(item.id)}><DeleteParkingIcon /></IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))}
              <Grid item xs={3}>
                <Link to={'/adding-parking-spaces'} style={{textDecoration: 'none'}}>
                  <Box sx={{
                      border: '1px solid #5D31B8',
                      backgroundColor: '#5D31B8',
                      borderRadius: '0.83vw',
                      padding: '0.77vw 1.03vw',
                      marginBottom: '0.5vw',
                      display: "flex",
                      height: '6vh',
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography sx={{color: '#fff', fontSize: '0.77vw', }}>Добавить парковку</Typography>
                    <Box sx={{marginLeft: '2vw', position: 'relative', top: '2px'}}><AddIcon /></Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
        {activeFormParking && (
          <Box sx={{ marginTop: '5vh'}}>
            <Box sx={{display: 'flex'}}>
              <Box><ParkingDataIcon /></Box>
              <Typography sx={{
                lineHeight: 1.1,
                fontSize: '1.03vw',
                marginBottom: '0.77vw',
                color: '#5D31B8',
                marginLeft: '1vw'
              }}>Данные о парковке</Typography>
            </Box>

             <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                minHeight: '70vh',
              }}>
                <FormProvider {...methods} >
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <PlaceAndPrices defaultValue={isEditParking} />
                    <Footer>
                      <Button
                        onClick={() => deleteParking()}
                        variant='contained'
                        sx={styleButton}
                        disabled={!activeFormParking}
                        disableRipple
                        >Удалить парковку</Button>
                      <Button 
                        onClick={onCancel}
                        variant='contained'
                        sx={styleButton}
                        disabled={!activeFormParking}
                        disableRipple
                        >Отменить</Button>
                      <Button 
                        type='submit'
                        variant='contained'
                        sx={{
                          ...styleButton,
                          backgroundColor: '#80CC5E',
                          '&:hover': {
                            backgroundColor: '#80CC5E',
                          },
                        }}
                        disabled={!activeFormParking}
                        disableRipple
                      >Сохранить</Button>
                    </Footer>
                  </form>
                </FormProvider>
             </Box>
          </Box>
        )}

      </Grid>

      <Grid item xs={1.3}></Grid>

    </Grid>
  </Page>)
}

export default Parking;