import React, {useState} from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { Tabs, Tab, Box, Button,Grid } from '@mui/material';
import {TabContext} from '@mui/lab';
import Schema from 'validate'
import { cloneDeep } from 'lodash';
import BasicInfo from './pages/BasicInfo';
// import Payment from './pages/Payment';
import AccessSystem from './pages/AccessSystem';
import LegalInfo from './pages/LegalInfo';
import PlaceAndPrices from "../../components/PlaceAndPrices";
import Page from '../../components/Page';
import {Footer, stylePage} from './styles';
import { defaultRequired } from './rules';
import Sidebar from '../../components/Sidebar';
import { useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import ParkingService from '../../services/parking.service'; 
import { YMaps } from '@pbe/react-yandex-maps';

const defaultValues = {
  parkingAddress: '',
  parkingType: '',
  parkingPhone: '',
  parkingPeriod: {
    hour:  {checked: true, price: 0},
    day:   {checked: true, price: 0},
    week:  {checked: true, price: 0},
    month: {checked: true, price: 0},
  },
  numberParkingSpaces: '10',
  parkingPhoto: null,
  regulations: false,
  accessSystemType: null,
  accessSystemQRCode: {
    nameLegalEntity: '',
    address: '',
  },
  coords: [],
}

const AddingParkingSpaces = (props) => {
  const parkingService = new ParkingService();
  const methods = useForm({defaultValues});
  const {setError} = methods
  const {handleSubmit} = methods;
  const {accessToken} = useAuth();
  let navigate = useNavigate();
  


  const tabs = [
    {
      name: 'Основная информация',
      key: 'basic-info',
      component: <BasicInfo/>
    },
    {
      name: 'Количество мест и цены',
      key: 'places-and-prices',
      component: <PlaceAndPrices/>
    },
    {
      name: 'Пропускная система',
      key: 'access-system',
      component: <AccessSystem/>
    },
    {
      name: 'Юридическая информация',
      key: 'legal-info',
      component: <LegalInfo />
    }
  ];
  const [value, setValue] = useState(tabs[0].key);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value)
  };

  const onSubmit = async (data) => {
    console.log("Parking -> data", data)
    let rules = cloneDeep(defaultRequired);
    const keys = Object.keys(data.parkingPeriod);
    keys.map(key => {
      if(data.parkingPeriod[key].checked) {
        if(!rules.parkingPeriod) {
          rules.parkingPeriod = {};
        }
        rules.parkingPeriod[key] = { price: {
          required: true,
        }}
      }
    })

    const user = new Schema(rules)
    const errors = user.validate(data)
    if(errors.length) {
      let objErrors = {};

      errors.map(err => {
        const {path:name, message} = err;
        if(name.includes('parkingPeriod')) {
          const arrName = name.split('.');
          if(!objErrors.parkingPeriod) {
            objErrors.parkingPeriod = {}  
          }
          objErrors.parkingPeriod[arrName[1]] = {
            price: {
              type: "required",
              message
            }
          }
          setError(name, {
            type: "required",
            message
          });
        } else {
          objErrors[name] = {
            type: "required",
            message
          }
          
          setError(name, {
            type: "required",
            message
          });
        }
      });
      onError(objErrors);

      console.log("🚀 ~ file: AddingParkingSpaces.js ~ line 95 ~ Parking ~ objErrors", objErrors)
    }
    console.log("🚀 ~ file: AddingParkingSpaces.js ~ line 82 ~ Parking ~ errors", errors)
    const reqData = {
      isFree: true,
      title: data.parkingAddress,
      address: data.parkingAddress,
      latitude: data.coords[0],
      longitude: data.coords[1],
      hourly: !!data.parkingPeriod.hour?.price,
      daily: !!data.parkingPeriod.day?.price,
      weekly: !!data.parkingPeriod.week?.price,
      monthly: !!data.parkingPeriod.month?.price,
      costPerHour: data.parkingPeriod.hour?.price || null,
      costPerDay: data.parkingPeriod.day?.price || null,
      costPerWeek: data.parkingPeriod.week?.price || null,
      costPerMonth: data.parkingPeriod.month?.price || null,
      useBankCard: true,
      parkingPhoto: data.parkingPhoto,
      phoneNumber: data.parkingPhone.split('').filter(c => c === '+' || (!isNaN(c) && c !== ' ')).join(''),
      businessType: data.parkingType,
      countOfParking: +data.numberParkingSpaces,
      type: 2,
      // accessSystemType: data.accessSystemType,
      // accessSystemQRCode: data.accessSystemQRCode
    }
    console.log("AddingParkingSpaces.js reqData: ", reqData)
    const response = await parkingService.createParking(accessToken, reqData)
    console.log("AddingParkingSpaces.js Response: ", response)
    if(response) {
      navigate('/parking')
    }
  }

  const clickSubmit = () => {
    console.log("🚀 ~ file: clickSubmit ~ 'click'")
    handleSubmit(onSubmit, onError)()
  }

  const onError = (e) => {
    console.log("🚀 ~ file: AddingParkingSpaces.js ~ line 85 ~ onError ~ e", e)
    if(e.parkingType || e.coords || e.phoneNumber || e.parkingAddress) {
      setValue('basic-info')
    } else if(e.parkingPeriod) {
      setValue('places-and-prices')
    } else if(e.accessSystemType) {
      setValue('access-system')
    } 
    // else if(e.offer || e.politics || e.regulations) {
      // setValue('legal-info')
    // }
  }

  const styleMain = {minHeight: "100vh", background: '#F0EFF4'}

  const styleTab = {
    color: '#000',
    fontWeight: 400,
    fontSize: '0.83vw',
    fontFamily: 'Roboto, Arial, sans-serif',
    textTransform: 'none',
    padding: '0.83vw'
  }

  const styleTabs = {
    marginTop: '8vh',
    '& .MuiTabs-indicator': {
      backgroundColor: '#5D31B8',
    },
  }

  const styleButton = {
    display: value !== 'legal-info' ? 'inline-flex' : 'none',
    backgroundColor: '#5D31B8',
    boxShadow: 'none',
    borderRadius: '0.83vw',
    padding: '1.05vw 1.55vw',
    textTransform: 'none',
    marginBottom: '5vh',
    fontWeight: 'normal',
    fontSize: '0.73vw',
    '&:hover': {
      backgroundColor: '#5D31B8',
    },
  }

  return <Page
      title="Добавление парковочных мест"
      style={stylePage}
    >
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={1.7}>
        <Sidebar />
      </Grid>
      
      <Grid item xs={1}></Grid>
      <Grid item xs={8}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor='primary'
              textColor='inherit'
              scrollButtons="auto"
              sx={styleTabs}
            >
              {tabs.map(tab => <Tab
                label={tab.name}
                value={tab.key}
                key={`tab_${tab.key}`}
                sx={styleTab}
              /> )}
            </Tabs>
          </Box>
              
          <Box>
            <FormProvider {...methods} >
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {tabs.map(tab => <YMaps key={`tabPanel_${tab.key}`}><Box
                  sx={{
                    display: tab.key === value ? 'flex' : 'none',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    minHeight: '70vh',
                  }}
                >{tab.component}</Box></YMaps> )}
              </form>
            </FormProvider>
          </Box>
          <Footer>
            <Button
              disableRipple
              onClick={clickSubmit}
              variant='contained'
              sx={styleButton}
            >Сохранить</Button>
          </Footer>
        </TabContext>
      </Grid>
      <Grid item xs={1.3}></Grid>
    </Grid>

  </Page>
}

export default AddingParkingSpaces;