import { 
  Grid, Typography, Box, TextField,
  Avatar, Badge,
  Button, IconButton,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from '../../utils/redux/apiCacheReducer/actions';
import { UploadPhotoIcon, EditIcon } from './SVGs';
import { fetchProfileData, fetchB2PUserData } from '../../thunks';
import { B2PStatus } from "../../constants/enums";
import React, { useState, useEffect } from 'react';
import Page from './../../components/Page';
import Sidebar from '../../components/Sidebar';
import Helmet from '../../components/Helmet';
import useAuth from '../../hooks/useAuth';
import ProfileService from '../../services/profile.service';

const Settings = props => {
  const allowedPhotoFormats = ['jpeg', 'png', 'gif'];
  
  const [b2pUser, setB2PUser] = useState({});
  const [userData, setUserData] = useState({});
  const [isEditNickname, setIsEditNickname] = useState(false);
  const [isEditAccID, setIsEditAccID] = useState(false);
  const [edittedNickname, setEdittedNickname] = useState('');
  const [edittedAccID, setEdittedAccID] = useState('');
  const { accessToken } = useAuth();
  const dispatch = useDispatch();
  const cachedProfileData = useSelector((state) => state.apiCacheReducer.profileData);
  
  const imageRef = React.useRef(null);
  const profileService = new ProfileService();

  const handleChangeAvatar = async ({target}) => {
    const file = target.files[0];
    console.log("handleChangeAvatar -> file", file)
    const uploadFile = allowedPhotoFormats.includes(file.type.replace('image/', '')) ? file : null;
    if(uploadFile) {
      const response = await profileService.updateAvatar(accessToken, uploadFile);
      const avatar = response?.avatar;
      if (avatar) {
        let { nickname, availableBalance } = cachedProfileData;
        nickname = nickname || userData.email;
        dispatch(setProfileData({ nickname, avatar: '/'+avatar, availableBalance }));
      }
    }
  }

  const handleChangeNickname = async () => {
    const response = await profileService.updateNickname(accessToken, edittedNickname);
    const nickname = response?.nickname;
    if (nickname) {
      let { avatar, availableBalance } = cachedProfileData;
      dispatch(setProfileData({ nickname, avatar, availableBalance }));
    }
  }

  const handleChangePhoneNumber = async () => {
    const redirectUrl = await profileService.getPhoneNumberLinkUrl(accessToken);
    window.open(redirectUrl, '_blank', 'noopener,noreferrer');
  }

  const handleConfirmAccount = async () => {
    const redirectUrl = await profileService.getUserDataLinkUrl(accessToken);
    window.open(redirectUrl, '_blank', 'noopener,noreferrer');
  }

  const handleChangeAccID = async () => {
    const resData = await profileService.updateAccID(accessToken, edittedAccID);
    if(resData) {
      setUserData(resData)
    }
  }

  useEffect(() => {
    fetchProfileData(accessToken).then(data => setUserData(data));
    fetchB2PUserData(accessToken).then(data => setB2PUser(data));
  }, [accessToken])

  const styleTypography = {
    color: "#7C7C7C", 
    fontSize: '0.7vw',
    marginBottom: '0.2vw'
  }
  const styleInput = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      padding: '0.1vw 0.3vw',
      borderRadius: '0.83vw',
      border: '1px solid',  
      borderColor: 'rgba(0, 0, 0, 0.23)',
      '& fieldset': {
        display: 'none',
      },
    },
    "& legend": {
      display: 'none',
    },
  }
  const styleButton = {
    display: 'inline-flex',
    backgroundColor: '#5D31B8',
    boxShadow: 'none',
    borderRadius: '0.83vw',
    padding: '0.8vw 2.1vw',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '0.83vw',
    '&:hover': {
      backgroundColor: '#5D31B8',
    },
  }
  const styleMain = {minHeight: "100vh", background: '#F0EFF4'}
  return (<Page title="Настройки">
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="stretch"
    sx={styleMain}
  >
    <Grid item xs={1.7}>
      <Sidebar />
    </Grid>
    
    <Grid item xs={1}></Grid>

    <Grid item xs={8}>
      <Helmet />

      <Box>
        <Typography sx={{
          fontSize: '1.55vw',
          color: '#5D31B8',
        }}>Настройки</Typography>
      </Box>

      <Box sx={{ 
        marginTop: '5vh',
        height: '12vw',
        backgroundColor: '#fff',
        borderRadius: '0.83vw',
        padding: '2vw'
      }}>
          
        <Box sx={{ display: 'flex' }}>
          <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <Box>
                  <input
                    ref={imageRef}
                    onChange={handleChangeAvatar}
                    type="file"
                    style={{ display: "none" }}
                  />
                  <Button 
                    type='button'
                    disableRipple
                    disableElevation
                    disableFocusRipple
                    onClick={() => imageRef.current.click()}
                    variant='contained'
                    sx={{
                      backgroundColor: '#5D31B8',
                      borderRadius: '0.55vw',
                      padding: '0.5vw 0.5vw',
                      minWidth: '0',
                      '&:hover': {
                        backgroundColor: '#5D31B8',
                      },
                    }}
                  ><UploadPhotoIcon /></Button>
                </Box>
              }
            >
              <Avatar
                sx={{
                  width: '5vw',
                  height: '5vw',
                  backgroundColor: '#C4C4C4',
                  fontSize: '1.24vw',
                  marginLeft: 'auto'
                }}
                variant="circular"
                alt={cachedProfileData.nickname}
                src={`${process.env.REACT_APP_API_BASE_URL}${cachedProfileData.avatar}`}
              />
            </Badge>
          </Box>

          <Box sx={{ 
            marginLeft: '1.5vw', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ fontWeight: 'medium', fontSize: '0.83vw' }}>{cachedProfileData.nickname}</Typography>
              <IconButton 
                disableRipple
                onClick={() => setIsEditNickname(true)}
                sx = {{ padding: '0 0 0 0.5vw', }}
              ><EditIcon /></IconButton>
            </Box>
            {(b2pUser?.active && (+b2pUser?.active !== B2PStatus.CONFIRMED)) && (
              <Button 
                onClick={handleConfirmAccount}
                disableRipple 
                disableFocusRipple
                sx={{
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                  padding: 0,
                  marginTop: '0.4vw',
                  '&:hover': {
                    backgroundColor: 'unset',
                  },
                }}
              >
                <Typography sx={{ fontSize: '0.83vw', color: '#5D31B8' }}>Подтвердить аккаунт</Typography>
              </Button>
            )}
          </Box>
        </Box>
        
        <hr size='1' style={{ color: '#C4C4C4', marginTop: '2vw' }} />

        <Box sx={{ marginTop: '2vw', display: 'flex', }}>
          <Box>
            <Typography sx={styleTypography}>Email</Typography>
            <Typography sx={{ fontSize: '0.83vw' }}>{userData.email}</Typography>
          </Box>

          <Box sx={{ marginLeft: '5vw' }}>
            <Typography sx={styleTypography}>Номер телефона</Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ fontSize: '0.83vw' }}>{b2pUser?.phone || 'Не привязан' }</Typography>
              <IconButton 
                disableRipple
                onClick={handleChangePhoneNumber}
                sx = {{ padding: '0 0 0 0.5vw', }}
              ><EditIcon /></IconButton>
            </Box>
          </Box>

          <Box sx={{ marginLeft: '5vw' }}>
            <Typography sx={styleTypography}>ID</Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ fontSize: '0.83vw' }}>{userData.accId || "Не указан"}</Typography>
              <IconButton 
                disableRipple
                onClick={() => setIsEditAccID(true)}
                sx = {{ padding: '0 0 0 0.5vw', }}
              ><EditIcon /></IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog open={isEditNickname} sx={{
        '& .MuiPaper-root': {
          width: '20vw',
          height: '13.6vw',
          padding: '0.5vw',
          borderRadius: '0.83vw'
        }
      }}>
        <DialogTitle>Изменить ник</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '0.5vw' }}>Ник</DialogContentText>
          <TextField
                sx={styleInput}
                variant="outlined"
                fullWidth
                value={edittedNickname}
                onChange={(event) => setEdittedNickname(event.target.value)}
              />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button 
            onClick={() => setIsEditNickname(false)}
            variant='contained'
            sx={styleButton}
            disableRipple
          >Отменить</Button>
          <Button 
            onClick={() => {
              if(!(!edittedNickname || /^\s*$/.test(edittedNickname))) {
                handleChangeNickname();
                setIsEditNickname(false);
              } 
            }}
            variant='contained'
            sx={{
              ...styleButton,
              backgroundColor: '#80CC5E',
              '&:hover': {
                backgroundColor: '#80CC5E',
              },
            }}
            disableRipple
          >Сохранить</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isEditAccID} sx={{
        '& .MuiPaper-root': {
          width: '20vw',
          height: '13.6vw',
          padding: '0.5vw',
          borderRadius: '0.83vw'
        }
      }}>
        <DialogTitle>Изменить ID</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '0.5vw' }}>ID</DialogContentText>
          <TextField
                sx={styleInput}
                variant="outlined"
                fullWidth
                value={edittedAccID}
                onChange={(event) => setEdittedAccID(event.target.value)}
              />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button 
            onClick={() => setIsEditAccID(false)}
            variant='contained'
            sx={styleButton}
            disableRipple
          >Отменить</Button>
          <Button 
            onClick={() => {
              handleChangeAccID();
              setIsEditAccID(false);
            }}
            variant='contained'
            sx={{
              ...styleButton,
              backgroundColor: '#80CC5E',
              '&:hover': {
                backgroundColor: '#80CC5E',
              },
            }}
            disableRipple
          >Сохранить</Button>
        </DialogActions>
      </Dialog>
    </Grid>

    <Grid item xs={1.3}></Grid>

  </Grid>
</Page>)
}

export default Settings;