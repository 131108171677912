import ApiManager from '../utils/apiManager'
import imageCompression from 'browser-image-compression';

export default class ProfileService {
  constructor() {
    this.apiManager = new ApiManager();
    this.compressionOptions = {
      maxSizeMB: 1,
      useWebWorker: true
    }
  }

  async getUserProfile(accessToken) {
    const response = await this.apiManager.get({
      url: '/api/user/profile',
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  
    return response?.data;
  }

  async getUserB2PProfile(accessToken) {
    const response = await this.apiManager.get({
      url: '/api/test/user/info',
      config: {
        headers: {
          Authorization: `token ${accessToken}`
        },
      },
    });
  
    return response?.data;
  }

  async updateAvatar(accessToken, file) {
    const compressedFile = await imageCompression(file, this.compressionOptions);
    let formData = new FormData();
    formData.append('avatar', compressedFile, file.name);
    const response = await this.apiManager.post({
      url: '/api/user/profile/avatar',
      data: formData,
      config: {
        headers: {
          Authorization: `token ${accessToken}`,
          'Content-Type': 'multipart/form-data'
        },
      },
    });
  
    return response?.data;
  }

  async updateNickname(accessToken, nickname) {
    const response = await this.apiManager.patch({
      url: '/api/user/profile',
      data: { nickname},
      config: {
        headers: {
          Authorization: `token ${accessToken}`,
        },
      },
    });

    return response?.data;
  }

  async updateAccID(accessToken, accId) {
    const response = await this.apiManager.patch({
      url: '/api/user/profile',
      data: { accId },
      config: {
        headers: {
          Authorization: `token ${accessToken}`,
        },
      },
    });

    return response?.data;
  }

  async getPhoneNumberLinkUrl(accessToken) {
    const response = await this.apiManager.get({
      url: '/api/user/b2p/phone',
      config: {
        headers: {
          Authorization: `token ${accessToken}`,
        },
      },
    });

    return response?.data?.redirectUrl;
  }

  async getUserDataLinkUrl(accessToken) {
    const response = await this.apiManager.get({
      url: '/api/user/b2p/data',
      config: {
        headers: {
          Authorization: `token ${accessToken}`,
        },
      },
    });

    return response?.data?.redirectUrl;
  }
}