import React, {useState, useEffect} from 'react';
import Page from './../../components/Page';
import { Grid, Typography, Box } from "@mui/material";
import DealsTable from "./DealsTable";
import DateRange from "./DateRange";
import Sidebar from '../../components/Sidebar';
import Helmet from '../../components/Helmet';
import useAuth from '../../hooks/useAuth';
import { fetchDeals } from '../../thunks';

const Deals = () => {
  const [createDate, setCreateDate] = useState([null, null]);
  const [closeDate, setCloseDate] = useState([null, null]);
  const [deals, setDeals] = useState(null);
  const [sortedDeals, setSortedDeals] = useState(null);
  
  const {accessToken} = useAuth();

  useEffect(() => {
    if(!deals) {
      fetchDeals(accessToken).then(data => {
        data.map(deal => {
          deal.address = deal.parking.address;
          deal.customer = deal.user.email;
          deal.startTimestamp = deal.startTimestamp*1000;
          deal.endTimestamp = deal.endTimestamp*1000;
          return deal;
        })
        setDeals(data);
      })
    }

    const byCreateDate = createDate.every(Boolean);
    const byCloseDate = closeDate.every(Boolean);
    if(byCreateDate || byCloseDate) {
      const newDeals = deals.filter(deal => {
        let passed = true;
        if(byCreateDate && passed) {
          passed = deal.startTimestamp >= createDate[0] && deal.startTimestamp <= createDate[1];
        }

        if(byCloseDate && passed) {
          passed = deal.endTimestamp >= closeDate[0] && deal.endTimestamp <= closeDate[1];
        }

        return passed;
      })
      
      setSortedDeals(newDeals);
    }
    
  }, [accessToken, closeDate, createDate, deals])
  
  const styleMain = {minHeight: "100vh", background: '#F0EFF4'}

  return (<Page title="Сделки">
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={1.7}>
        <Sidebar />
      </Grid>
      
      <Grid item xs={1}></Grid>

      <Grid item xs={8}>
        <Helmet />

        <Box>
          <Typography sx={{
            fontSize: '1.55vw',
            color: '#5D31B8',
          }}>Сделки</Typography>
        </Box>

        <Box sx={{ marginTop: '3vh'}}>
          <Box sx={{display: 'flex'}}>
            <Box><DateRange title="По дате создания" onChange={setCreateDate} /></Box>
            <Box sx={{marginLeft: '5vw'}}><DateRange title="По дате закрытия"  onChange={setCloseDate}/></Box>
          </Box>
        </Box>

        <Box sx={{marginTop: '5vh'}}>
          <DealsTable deals={createDate.every(Boolean) || closeDate.every(Boolean) ? sortedDeals : deals || []} />
        </Box>
      </Grid>

      <Grid item xs={1.3}></Grid>

    </Grid>
  </Page>)
}

export default Deals;