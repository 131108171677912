import React, { useEffect } from 'react';
import { COLOR_PURPLE } from '../../constants/appConst'
import { Button, Box, Typography, IconButton } from '@mui/material';
import { 
  ParkifyLogoSB, 
  // AnalyticsIconSB, 
  ParkingIconSB, 
  DealsIconSB, 
  UpdateIcon,
  SettingsIconSB 
} from '../../layouts/SVGs';
import { useLocation, Link } from "react-router-dom";
import {
  LinkName,
  SidebarTab
} from './styles'
import { fetchB2PUserData } from '../../thunks';
import { useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../utils/redux/registerReducer/actions';
import { setProfileData } from '../../utils/redux/apiCacheReducer/actions';
import { fetchProfileData } from '../../thunks'
import { fetchWithdrawLink } from '../../thunks/withdraw-link';
import { B2PStatus } from '../../constants/enums';

function Sidebar(props) {
  const menu = [
    // {href: '/analytics', name: 'Аналитика', icon: <AnalyticsIconSB />},
    {href: '/parking', name: 'Парковки', icon: <ParkingIconSB />},
    {href: '/deals', name: 'Сделки', icon: <DealsIconSB />},
    {href: '/settings', name: 'Настройки', icon: <SettingsIconSB />},
  ];
  
  const [b2pUser, setB2PUser] = React.useState({});
  const [balance, setBalance] = React.useState(0);
  const [updateBalance, setUpdateBalance] = React.useState(false);
  const location = useLocation();
  const pathname = (location?.pathname || '/').split('/').filter(item => item.length)[0];

  const dispatch = useDispatch();
  const {accessToken} = useAuth();
  let navigate = useNavigate();
  
  const onLogout = () => {
    if(accessToken) {
      dispatch(logout())
    }
    const route = "/login";
    navigate(route);
  }

  const onWithdraw = () => {
    fetchWithdrawLink(accessToken, balance).then(link => {
      window.open(link, '_blank', 'noopener,noreferrer');
    })
  }

  const onUpdateBalance = () => {
    setUpdateBalance(true)
    let nickname, avatar, availableBalance;
    fetchProfileData(accessToken).then(data => {
      if(!data) return;
      ({ nickname, avatar, availableBalance } = data);
      nickname = nickname || data.email;
      dispatch(setProfileData({ nickname, avatar, availableBalance }));
      setUpdateBalance(false)
    })
  }

  const cachedProfileData = useSelector((state) => state.apiCacheReducer.profileData);

  useEffect(() => {
    fetchB2PUserData(accessToken).then(data => setB2PUser(data));

    let nickname, avatar, availableBalance;

    if (Object.values(cachedProfileData).some(Boolean)) {
      ({ nickname, avatar, availableBalance } = cachedProfileData);
    } else {
      fetchProfileData(accessToken).then(data => {
        if(!data) return;
        ({ nickname, avatar, availableBalance } = data);
        nickname = nickname || data.email;
        dispatch(setProfileData({ nickname, avatar, availableBalance }));
      })
    }

    setBalance(availableBalance);
  }, [accessToken, cachedProfileData, dispatch])

  return (
      <Box sx={{ background: COLOR_PURPLE, height: '100vh', position: 'fixed', width: '15vw'  }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '15%' }}>
          <Link to={'/adding-parking-spaces'}> 
            <ParkifyLogoSB />
          </Link>
        </Box>
        
        <Box sx={{
          marginLeft: '1.5vw',
          marginBottom: '5vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '12vh'
        }}>
          <Box>
            <Typography sx={{ color: '#fff', fontSize: '0.83vw' }}>Баланс</Typography>
          </Box>

          <Box sx={{ marginBottom: '1vh' }}>
            <Typography sx={{
              color: '#80CC5E',
              fontSize: '1.45vw',
              fontWeight: 'bold'
            }}>
              {balance} ₽
              <IconButton 
                sx={{
                  padding: '0vw 0vw 0.3vw 0.5vw',
                }}
                disableRipple
                onClick={onUpdateBalance}
              >
                <Box sx={updateBalance ? {
                  width: '1.5vw',
                  height: '1.5vw',
                  animationName: 'spin',
                  animationDuration: '1000ms',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear',
                  '@keyframes spin': {
                    from: {
                        transform: 'rotate(0deg)'
                    },
                    to: {
                        transform: 'rotate(360deg)'
                    }
                  }
                } : { width: '1.5vw', height: '1.5vw' }}><UpdateIcon /></Box>
              </IconButton>
            </Typography>
          </Box>

          <Box>
            <Button
              onClick={onWithdraw}
              disableRipple 
              disabled={balance <= 10 || (b2pUser?.active && (+b2pUser?.active !== B2PStatus.CONFIRMED))}  
              sx={{
                display: 'inline-flex',
                backgroundColor: '#80CC5E',
                color: '#fff',
                '&.Mui-disabled ':{
                  color: '#fff',
                  backgroundColor: '#7145CD'
                },
                boxShadow: 'none',
                borderRadius: '0.83vw',
                padding: '0.77vw 2.59vw',
                textTransform: 'none',
                fontWeight: 'normal',
                '&:hover': {
                  backgroundColor: '#80CC5E',
                },
              }}
            >
              <Typography sx={{ fontSize: '0.83vw' }}>
                Вывести
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box>
          {menu.map(({href, name, icon}, index) => (
            <SidebarTab key={`tab_${index}`} selected={href.includes(pathname)}>
              <Link to={href} style={{textDecoration: 'none'}}>
              <Box sx={{
                display: 'inline-block',
                position: 'relative',
              }}>
                {icon}
              </Box>
              <LinkName>{name}</LinkName> 
              </Link>
            </SidebarTab>
          ))}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: '5vh',
            left: '50%',
            transform: 'translateX(-50%)'
          }}  
        >
          <Button 
            color="inherit"
            disableRipple
            sx={{
              color: "#fff",
              fontFamily: 'Helvetica Neue, Roboto, Arial, sans-serif;',
              fontSize: '0.72vw',
              fontWeight: '400',
              letterSpacing: '0.25vw',
              '&:hover': {
                backgroundColor: 'unset',
              },
            }}
            onClick={onLogout}
          >ВЫХОД</Button>
        </Box>
    
      </Box>
  );
}

export default Sidebar;