import React from 'react';

const sizes = {
  width: '1.5vw',
  height: '1.5vw'
}

const UpdateIcon = () => {	return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={sizes} viewBox="0 0 48.000000 48.000000" preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
fill="#80CC5E" stroke="none">
<path d="M165 401 c-22 -10 -48 -27 -57 -37 -24 -27 -50 -93 -44 -110 10 -25
25 -15 48 33 41 86 124 116 197 72 l33 -20 -21 -23 c-11 -12 -21 -26 -21 -30
0 -4 26 -6 58 -4 l57 3 3 58 c3 60 -1 66 -28 42 -14 -13 -21 -12 -65 10 -57
30 -105 31 -160 6z"/>
<path d="M368 193 c-28 -58 -63 -84 -119 -90 -33 -3 -52 1 -78 17 l-33 21 21
23 c11 12 21 26 21 30 0 4 -26 6 -57 4 l-58 -3 -3 -57 c-3 -61 1 -67 28 -43
14 13 21 12 65 -10 26 -14 65 -25 84 -25 42 0 108 29 133 56 24 27 50 93 44
110 -10 25 -25 15 -48 -33z"/>
</g>
</svg>
)
}

export default UpdateIcon;

