import ApiManager from '../utils/apiManager'

export default class UtilsService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async getAddressByCoords(accessToken, params) {
    const response = await this.apiManager.get({
      url: '/api/user/profile/parking/address',
      config: {
        params: {
          geocode: `${params.longitude},${params.latitude}`,
          lang: params.lang,
          api_key: process.env.REACT_APP_YANDEX_API_KEY,
          format: 'json',
        },
        headers: {
          Authorization: `token ${accessToken}`,
        },
      },
    });

    const address = response?.data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject?.metaDataProperty?.GeocoderMetaData?.text?.replace('Россия, ', '') || '';
    return address;
  }

  async getWithdrawLink(accessToken, data) {
    const response = await this.apiManager.post({
      url: '/api/user/profile/credit',
      data,
      config: {
        headers: {
          Authorization: `token ${accessToken}`,
        },
      },
    });

    return response.data;
  }

}